.global-menu {
  .MuiListItem-root {
    flex-direction: row-reverse;

    svg.MuiSvgIcon-root {
      margin: 0px 20px 0px -6px;
    }
  }

  .menu-icon {
    margin: 0px 25px 0px 0px;
  }
  .MuiCollapse-wrapper {
   li {
     padding: 0px 0px 0px 40px;
   }
  }

  .sub-link {
    .MuiListItem-button {
      padding-left: 80px;

      span {
        color: rgba(0,0,0,0.5);
      }
    }
    &.active {
      .MuiListItem-button {
        position: relative;
        span {
          color: rgba(0,0,0,1);
        }
        &:before {
          content: '';
          position: absolute;
          width: 4px;
          height: 100%;
          display: flex;
          background: #B99A66;
          left: 0;
        }
        background: rgba(0,0,0,0.05);
      }
    }
  }
}