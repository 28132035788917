.custom-style-table {
  .custom-td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #000000;

    &.name {
      color: #133362;
    }

    &.index {
      color: rgba(0, 0, 0, 0.4);
    }

    > span {
      border-radius: 20px;
      padding: 5px 10px;
      display: inline-block;
      background-color: red;

      &.success {
        color: #10c659;
        background: rgba(16, 198, 89, 0.16);
      }

      &.warning {
        color: #ff2727;
        background: rgba(255, 21, 21, 0.08);
      }
    }
  }

  .status {
    display: flex;
    span {
      height: 24px;
      border-radius: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.25px;
      padding: 0px 12px;
      width: max-content;
    }

    &.active {
      span {
        color: rgba(16, 198, 89, 0.87);
        background: rgba(16, 198, 89, 0.16);
      }
    }
    &.inactive {
      span {
        background: rgba(255, 21, 21, 0.08);
        color: rgba(255, 39, 39, 0.87);
      }
    }
  }
}
.packages-title {
  padding-top: 25px;
}
.custom-table-list {
  .MuiTableCell-head {
    font-weight: bold;
  }
  .MuiTableSortLabel-root {
    font-weight: bold;
  }

  .MuiTableCell-paddingCheckbox {
    display: none;
  }

  .text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #000000;

    &.name {
      color: #133362;
    }

    &.active {
      color: rgba(16, 198, 89, 0.87);
      background: rgba(16, 198, 89, 0.16);
    }

    &.inactive {
      background: rgba(255, 21, 21, 0.08);
      color: rgba(255, 39, 39, 0.87);
    }

    &.pending-verification {
      background: rgba(234, 163, 0, 0.1);
      color: rgba(234, 163, 0, 1);
    }

    &.status-field {
      height: 24px;
      min-height: 24px;
      display: flex;
      width: max-content;
      align-items: center;
      padding: 0px 12px;
      border-radius: 16px;
    }
  }
}
