.profile-page {
  background: #fff;
  padding: 20px;
  border-radius: 10px;

  .form-data {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -10px;

    .custom-input {
      margin-bottom: 20px;
    }

    input {
      width: 350px;
      height: 48px;
      background: #F2F4F6;
      padding: 0px 20px;
      box-sizing: border-box;
    }
  }

}