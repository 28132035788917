.btn {
  color: #fff;
  background-color: #133362 !important;
  border-radius: 100px !important;
  min-height: 47px;
  padding: 0px 20px !important;
  span {
    color: #fff;
  }

  svg {
    path {
      fill: #fff;
    }
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.8;
    cursor: default;
  }
}

.pull-right {
  margin-left: auto !important;
}

.custom-btn {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  &.right-btn {
    position: absolute;
    right: 80px;
  }
  &:focus {
    outline: none;
  }

  svg {
    margin-right: 13px;
  }
}

.btn-delete {
  color: #e32222;
  margin: 0 30px !important;

  span {
    color: #e32222;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }

  svg {
    path {
      fill: #e32222;
    }
  }
}

.btn-deactivate {
  color: #febf1e;
}

.outlive-delete {
  background-color: #e32222 !important;
  border: none !important;

  span {
    color: #fff;
  }
}
