.custom-filters {
  &__search-input {
      min-width: 390px;
      width: 390px;

      .MuiFilledInput-root {
        background-color: #fff;

        &.Mui-focused {
          background-color: #fff !important;
        }

        &::before {
          border-bottom: 1px solid rgba(102,102,102,0.3);
        }

        &:active {
          background-color: #fff;
        }

        &:focus {
          background-color: #fff;
        }

        &:hover {
          background-color: #fff;

          &::before {
            border-bottom: 1px solid rgba(102,102,102,0.3);
          }
        }
      }

      svg {
        path {
          fill: rgba(0, 0, 0, 0.6);
        }
      }

      input {
        width: 100%;
        height: 48px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px 4px 0 0;
      }
    }

  &__custom-date-input {

    .MuiFilledInput-root {
      background-color: #fff;

      &::before {
        border-bottom: 1px solid rgba(102,102,102,0.3);
      }

      &:active {
        background-color: #fff;
      }

      &:focus {
        background-color: #fff;
      }

      &:hover {
        background-color: #fff;

        &::before {
          border-bottom: 1px solid rgba(102,102,102,0.3);
        }
      }
    }

    input {
      background: #fff;
      cursor: pointer;
    }
  }
}