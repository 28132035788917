.PhoneInput {
  min-width: 350px !important;
  margin: 8px 10px 0px 10px !important;
  display: flex !important;
  align-items: center !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  height: 48px !important;
  background: rgba(0, 0, 0, 0.09) !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  padding: 0 17px !important;
  box-sizing: border-box !important;
  position: relative !important;
  transition: background 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  &--disabled {
    max-width: 350px !important;
    margin: 0 10px 20px 10px !important;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.38) !important;
    padding: 0 !important;
    input {
      height: 47px !important;
      color: rgba(0, 0, 0, 0.38) !important;
    }
    .PhoneInputCountry {
      margin-right: 0;
      background: #f2f4f6;
      padding-left: 20px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    bottom: 0;
    left: 0;
    background-color: #3f51b5;
    transform-origin: bottom center;
    transition: transform 0.25s ease-out;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.13) !important;
  }
  &-error {
    border-bottom: 2px solid #f44336 !important;
    border-bottom-color: #f44336 !important;
  }
  &:focus:not(.PhoneInput-error)::after,
  &:focus-visible:not(.PhoneInput-error)::after,
  &:focus-within:not(.PhoneInput-error)::after {
    transform: scaleX(1);
    transform-origin: bottom center;
  }
  .PhoneInputCountrySelectArrow {
    border-bottom-width: 1.5px;
    border-right-width: 1.5px;
    border-color: rgba(0, 0, 0, 0.42);
  }
  .PhoneInputCountryIcon--border {
    box-shadow: none;
  }
  input {
    height: 48px;
    font-weight: 400;
    font-size: 1rem;
    padding-left: 7px;
    background: rgba(0, 0, 0, 0);

    &:focus {
      outline: none !important;
    }
  }
  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: none !important;
  }
}
.edit-input {
  background: rgba(0, 0, 0, 0.12) !important;
  margin: 8px 10px 20px 10px !important;
  &:hover {
    background: rgba(0, 0, 0, 0.12) !important;
  }
  .PhoneInputCountry {
    background: none !important;
  }
}

.phone-number-error {
  color: #f44336;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  position: relative;
  right: 345px;
  top: 59px;
}
.RaSidebar-fixed-41 {
  width: 255px !important;
}
