.custom-toolbar {
  background-color: #fff !important;
  border-radius: 0 0 10px 10px;
  padding-bottom: 24px;
  padding-top: 24px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-wrap: wrap;

  .wrap-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;

    .group-button {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn-delete {
        margin-left: 60px;
      }
    }
  }

  .w100 {
    min-width: 100%;
  }
}

.user-list {
  padding: 10px 0;
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}