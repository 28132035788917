.req-module {
    border-radius: 15px;
    padding: 5px 10px;

    &-waiting {
        color: #10C659;
        background: rgba(16, 198, 89, 0.16);
    }

    &-in_progress {
        color: #FF2727;
        background: rgba(255, 21, 21, 0.08);
    }

    &-done {
        color: #0850a7;
        background: rgba(21, 56, 255, 0.08);
    }
}