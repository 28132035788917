.custom-form {
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 10px;

  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    padding: 32px;
    padding-top: 32px !important;

    .form-group {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0 -10px;
    }

    .custom-table {
      width: 100%;
    }

    h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #000000;
      margin-bottom: 25px;
    }
  }
}

.MuiSnackbarContent-root {
  &.jss35 {
    background-color: #e57373 !important;
  }
  &.jss34 {
    background-color: #d32f2f !important;
  }
  &:not([class*="RaNotification"]) {
    background-color: rgb(76, 175, 80);
  }
}
