.admin-user-info {
  margin-bottom: 15px;
  display: flex;
  align-content: center;

  .avatar {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    border-radius: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info {
    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      color: #111111;
    }
    .create-ad {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #767676;
    }
  }
}