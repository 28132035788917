body,
html,
p,
h1,
h2,
a,
label,
textarea,
ul,
* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  border: 0;
  color: #000000;
}

a {
  text-decoration: none;
}

button {
  background: transparent;
  background-color: transparent;
  cursor: pointer;
}

main {
  background: #F2F4F6;
}