.appbar-user-info {
  background-color: #fff !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(33, 33, 33, 0.08) !important;

  .transparens {
    position: absolute;
    left: 0;
    height: 100%;
    width: 60px;
    z-index: 2;
  }

  .MuiIconButton-root {
    padding: 5px 10px;
    border-radius: 10px;

    &:first-child {
      z-index: 10;
    }
  }
}